<template>
  <!-- begin .service-calculator-->
  <form
    class="service-calculator"
    :class="{
      'service-calculator_state_success': order.id
    }"
    @submit.prevent="preventForm"
  >
    <template v-if="isReady">
      <div class="service-calculator__main">
        <div class="service-calculator__cell service-calculator__cell_order_1">
          <div class="service-calculator__panel">
            <div class="service-calculator__destination">
              <div class="service-calculator__line">
                <!-- begin .form-control-->
                <!-- Modifiers-->
                <!-- form-control_state_invalid - red border, one of the two options to show invalid field-->
                <div class="form-control form-control_size_l form-control_offset_left">
                  <label class="form-control__holder">
                    <span class="form-control__field">
                      <span
                        class="form-control__decoration"
                        :style="[!ymapsIsReady ? { opacity: .3 } : '']"
                      >
                        <svg
                          width="10"
                          height="13"
                          viewBox="0 0 10 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          class="form-control__icon"
                        >
                          <path
                            d="M4.99967 13C4.15763 12.2818 3.37714 11.4944 2.66634 10.646C1.59968 9.37202 0.333008 7.47469 0.333008 5.66669C0.332546 4.74333 0.606014 3.84058 1.1188 3.0727C1.63159 2.30482 2.36066 1.70633 3.21372 1.35295C4.06679 0.999578 5.00551 0.907214 5.91109 1.08755C6.81666 1.26788 7.64839 1.71281 8.30101 2.36602C8.73551 2.79859 9.07991 3.31305 9.31427 3.8796C9.54862 4.44616 9.66829 5.05357 9.66634 5.66669C9.66634 7.47469 8.39967 9.37202 7.33301 10.646C6.62221 11.4944 5.84171 12.2818 4.99967 13ZM4.99967 3.66669C4.46924 3.66669 3.96053 3.8774 3.58546 4.25247C3.21039 4.62755 2.99968 5.13625 2.99968 5.66669C2.99968 6.19712 3.21039 6.70583 3.58546 7.0809C3.96053 7.45597 4.46924 7.66669 4.99967 7.66669C5.53011 7.66669 6.03882 7.45597 6.41389 7.0809C6.78896 6.70583 6.99967 6.19712 6.99967 5.66669C6.99967 5.13625 6.78896 4.62755 6.41389 4.25247C6.03882 3.8774 5.53011 3.66669 4.99967 3.66669Z"
                          />
                        </svg>
                      </span>
                      <!-- Modifiers-->
                      <!-- form-control__input_state_invalid - red border, one of the two options to show invalid field-->
                      <input
                        ref="from"
                        type="text"
                        class="form-control__input"
                        :class="{
                          'form-control__input_state_invalid': routError
                        }"
                        :placeholder="labels.from.placeholder"
                        :disabled="!ymapsIsReady"
                        @change.prevent="enterRoutePoint()"
                      >
                    </span>
                  </label>
                </div>
                <!-- end .form-control-->
              </div>
              <div class="service-calculator__line">
                <!-- begin .form-control-->
                <!-- Modifiers-->
                <!-- form-control_state_invalid - red border, one of the two options to show invalid field-->
                <div class="form-control form-control_size_l form-control_offset_left">
                  <label class="form-control__holder">
                    <span class="form-control__field">
                      <span
                        class="form-control__decoration"
                        :style="[!ymapsIsReady ? { opacity: .3 } : '']"
                      >
                        <svg
                          width="10"
                          height="13"
                          viewBox="0 0 10 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          class="form-control__icon"
                        >
                          <path
                            d="M4.99967 13C4.15763 12.2818 3.37714 11.4944 2.66634 10.646C1.59968 9.37202 0.333008 7.47469 0.333008 5.66669C0.332546 4.74333 0.606014 3.84058 1.1188 3.0727C1.63159 2.30482 2.36066 1.70633 3.21372 1.35295C4.06679 0.999578 5.00551 0.907214 5.91109 1.08755C6.81666 1.26788 7.64839 1.71281 8.30101 2.36602C8.73551 2.79859 9.07991 3.31305 9.31427 3.8796C9.54862 4.44616 9.66829 5.05357 9.66634 5.66669C9.66634 7.47469 8.39967 9.37202 7.33301 10.646C6.62221 11.4944 5.84171 12.2818 4.99967 13ZM4.99967 3.66669C4.46924 3.66669 3.96053 3.8774 3.58546 4.25247C3.21039 4.62755 2.99968 5.13625 2.99968 5.66669C2.99968 6.19712 3.21039 6.70583 3.58546 7.0809C3.96053 7.45597 4.46924 7.66669 4.99967 7.66669C5.53011 7.66669 6.03882 7.45597 6.41389 7.0809C6.78896 6.70583 6.99967 6.19712 6.99967 5.66669C6.99967 5.13625 6.78896 4.62755 6.41389 4.25247C6.03882 3.8774 5.53011 3.66669 4.99967 3.66669Z"
                          />
                        </svg>
                      </span>
                      <!-- Modifiers-->
                      <!-- form-control__input_state_invalid - red border, one of the two options to show invalid field-->
                      <input
                        ref="to"
                        type="text"
                        class="form-control__input"
                        :class="{
                          'form-control__input_state_invalid': routError
                        }"
                        :placeholder="labels.to.placeholder"
                        :disabled="!ymapsIsReady"
                        @keyup.prevent="enterRoutePoint()"
                      >
                    </span>
                    <span
                      v-if="false"
                      class="form-control__messages"
                    >
                      <span class="form-control__message form-control__message_style_error">
                        Не удалось построить путь {{ from }} - {{ to }}
                      </span>
                    </span>
                  </label>
                </div>
                <!-- end .form-control-->
              </div>
            </div>
          </div>
        </div>
        <div class="service-calculator__cell service-calculator__cell_order_2 service-calculator__cell_width_m">
          <div class="service-calculator__panel">
            <div class="service-calculator__row service-calculator__row_align_center">
              <div class="service-calculator__col">
                <div class="service-calculator__quantity-input">
                  <!-- begin .quantity-input-->
                  <div class="quantity-input">
                    <div class="quantity-input__label">
                      {{ labels.loader.text }}
                    </div>
                    <div class="quantity-input__wrapper">
                      <div class="quantity-input__control">
                        <button
                          type="button"
                          class="quantity-input__button quantity-input__button_type_decrease"
                          :disabled="numberOfLoader === 0"
                          @click="numberOfLoader--"
                        >
                          {{ labels.ui.decrease }}
                        </button>
                      </div>
                      <div class="quantity-input__field">
                        <input
                          v-model="numberOfLoader"
                          type="text"
                          readonly
                          class="quantity-input__input"
                        >
                      </div>
                      <div class="quantity-input__control">
                        <button
                          type="button"
                          class="quantity-input__button quantity-input__button_type_increase"
                          :disabled="totalNumberOfPeople >= maximumNumberOfPeople"
                          @click="numberOfLoader++"
                        >
                          {{
                            labels.ui.increase
                          }}
                        </button>
                      </div>
                    </div>
                  </div>
                  <!-- end .quantity-input-->
                </div>
              </div>
              <div class="service-calculator__col">
                <div class="service-calculator__quantity-input">
                  <!-- begin .quantity-input-->
                  <div class="quantity-input">
                    <div class="quantity-input__label">
                      {{ labels.passengers.text }}
                    </div>
                    <div class="quantity-input__wrapper">
                      <div class="quantity-input__control">
                        <button
                          type="button"
                          class="quantity-input__button quantity-input__button_type_decrease"
                          :disabled="numberOfPassengers === 0"
                          @click="numberOfPassengers--"
                        >
                          {{ labels.ui.decrease
                          }}
                        </button>
                      </div>
                      <div class="quantity-input__field">
                        <input
                          v-model="numberOfPassengers"
                          type="text"
                          readonly
                          class="quantity-input__input"
                        >
                      </div>
                      <div class="quantity-input__control">
                        <button
                          type="button"
                          class="quantity-input__button quantity-input__button_type_increase"
                          :disabled="totalNumberOfPeople >= maximumNumberOfPeople"
                          @click="numberOfPassengers++"
                        >
                          {{
                            labels.ui.increase
                          }}
                        </button>
                      </div>
                    </div>
                  </div>
                  <!-- end .quantity-input-->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="service-calculator__cell service-calculator__cell_order_4">
          <div class="service-calculator__panel">
            <div class="service-calculator__carousel">
              <VehicleCarousel
                :slides="auto"
                :current-index="currentAutoIndex"
                @change="changeAuto"
              />
            </div>
          </div>
        </div>
        <div class="service-calculator__cell service-calculator__cell_order_3 service-calculator__cell_width_s">
          <div
            class="service-calculator__panel service-calculator__panel_type_padded service-calculator__panel_type_mobile-inline"
          >
            <div class="service-calculator__switch">
              <!-- begin .switch-->
              <label class="switch">
                <input
                  v-model="moveToUpstairs"
                  type="checkbox"
                  class="switch__input"
                >
                <span class="switch__body">
                  <span class="switch__container">
                    <span class="switch__wrapper">
                      <span class="switch__knob">&nbsp;</span>
                    </span>
                  </span>
                  <span class="switch__label">{{ labels.moveToUpstairs.text }}</span>
                </span>
              </label>
              <!-- end .switch-->
            </div>
            <div class="service-calculator__line">
              <!-- begin .form-control-->
              <!-- Modifiers-->
              <!-- form-control_state_invalid - red border, one of the two options to show invalid field-->
              <div class="form-control form-control_size_l form-control_align_center">
                <label class="form-control__holder">
                  <span class="form-control__field">
                    <!-- Modifiers-->
                    <!-- form-control__input_state_invalid - red border, one of the two options to show invalid field-->
                    <input
                      ref="moveToUpstairs"
                      v-model="floor"
                      type="number"
                      class="form-control__input"
                      :placeholder="labels.moveToUpstairs.placeholder"
                      :disabled="!moveToUpstairs"
                      min="2"
                    >
                  </span>
                </label>
              </div>
              <!-- end .form-control-->
            </div>
          </div>
        </div>
        <div class="service-calculator__cell service-calculator__cell_order_5 service-calculator__cell_width_m">
          <div class="service-calculator__panel">
            <div class="service-calculator__row service-calculator__row_type_mobile-column">
              <div class="service-calculator__col">
                <!-- begin .form-control-->
                <!-- Modifiers-->
                <!-- form-control_state_invalid - red border, one of the two options to show invalid field-->
                <div class="form-control form-control_size_l form-control_align_center form-control_inline_mobile">
                  <label class="form-control__holder">
                    <span class="form-control__label">{{ labels.dateTime.text }}</span>
                    <span class="form-control__field">
                      <!-- Modifiers-->
                      <!-- form-control__input_state_invalid - red border, one of the two options to show invalid field-->
                      <input
                        ref="dateTime"
                        type="text"
                        class="form-control__input"
                        :class="{
                          'form-control__input_state_invalid': !dateTime
                        }"
                        :placeholder="labels.dateTime.placeholder"
                        required="required"
                        readonly="readonly"
                      >
                      <input
                        ref="timestamp"
                        type="hidden"
                        @change="dateTimeChange"
                      >
                    </span>
                    <input
                      type="hidden"
                      class="js-date-and-time-hidden-time"
                    >
                    <input
                      type="hidden"
                      class="js-date-and-time-hidden-date"
                    >
                  </label>
                </div>
                <!-- end .form-control-->
              </div>
              <div class="service-calculator__col">
                <!-- begin .form-control-->
                <!-- Modifiers-->
                <!-- form-control_state_invalid - red border, one of the two options to show invalid field-->
                <div
                  class="form-control form-control_size_l form-control_style_transparent form-control_align_center form-control_inline_mobile-l"
                >
                  <label class="form-control__holder">
                    <span class="form-control__label">{{ labels.duration.text }}</span>
                    <span class="form-control__field">
                      <!-- Modifiers-->
                      <span
                        class="form-control__input"
                        v-html="path.time.humanJamsValue || '-'"
                      />
                    </span>
                  </label>
                </div>
                <!-- end .form-control-->
              </div>
            </div>
          </div>
        </div>
        <div class="service-calculator__cell service-calculator__cell_order_6 service-calculator__cell_width_s">
          <div class="service-calculator__panel service-calculator__panel_type_padded">
            <!-- begin .form-control-->
            <!-- Modifiers-->
            <!-- form-control_state_invalid - red border, one of the two options to show invalid field-->
            <div class="form-control form-control_size_l form-control_align_center form-control_inline_mobile-s">
              <label class="form-control__holder">
                <span class="form-control__label">{{ labels.promoCode.text }}</span>
                <span class="form-control__field">
                  <!-- Modifiers-->
                  <!-- form-control__input_state_invalid - red border, one of the two options to show invalid field-->
                  <input
                    ref="promoCode"
                    v-model="promoCode"
                    type="text"
                    class="form-control__input"
                    :placeholder="labels.promoCode.placeholder"
                    :disabled="!price"
                    @keyup="checkPromoCode"
                  >
                </span>
                <span class="form-control__messages">
                  <span
                    v-if="isLoading.promoCode"
                    class="form-control__message"
                  > {{ labels.promoCode.checking }}</span>
                  <span
                    v-if="promoCodeChecked && !isLoading.promoCode && promoDiscount.value === 0"
                    class="form-control__message form-control__message_style_error"
                  >{{ labels.promoCode.notFound
                  }}</span>
                </span>
              </label>
            </div>
            <!-- end .form-control-->
          </div>
        </div>
        <div
          v-if="!authUserPhoneNumber"
          class="service-calculator__cell service-calculator__cell_order_7"
        >
          <div class="service-calculator__panel">
            <div class="service-calculator__verification">
              <div class="service-calculator__verification-group">
                <div class="service-calculator__line">
                  <!-- begin .form-control-->
                  <!-- Modifiers-->
                  <!-- form-control_state_invalid - red border, one of the two options to show invalid field-->

                  <div
                    class="form-control form-control_size_l form-control_type_has-check"
                    :class="{
                      'form-control_state_success': phoneNumberConfirmed,
                      'form-control_state_invalid': phoneNumberError
                    }"
                  >
                    <label class="form-control__holder">
                      <span class="form-control__label">
                        {{ labels.phoneNumber.text }}
                      </span>
                      <span class="form-control__field">
                        <!-- Modifiers-->
                        <!-- form-control__input_state_invalid - red border, one of the two options to show invalid field-->

                        <input
                          v-maska="phoneNumber"
                          data-maska="+7 (###) ###-##-##"
                          type="tel"
                          :placeholder="labels.phoneNumber.placeholder"
                          class="form-control__input"
                          :disabled="isLoading.verificationCode || timeoutBlocking"
                        />
                      </span>
                      <span class="form-control__messages" v-if="timeoutBlocking">
                        <span class="form-control__message">
                          Запросить код повторно можно через {{ timeoutCounter }} сек.
                        </span>
                      </span>
                    </label>
                  </div>
                  <!-- end .form-control-->
                </div>
                <div class="service-calculator__line-control">
                  <div class="service-calculator__control">
                    <!-- begin .button-->
                    <button
                      class="button button_width_full button_size_m button_text-size_s button_weight_normal button_style_no-shadow"
                      type="button"
                      :disabled="!phoneNumber.completed || timeoutBlocking"
                      @click="sendVerifySms"
                    >
                      <span v-if="!isLoading.verificationSms" class="button__holder">{{ labels.phoneNumber.send }}</span>
                      <span v-if="isLoading.verificationSms" class="button__holder">{{ labels.phoneNumber.loading }}</span>
                    </button>
                    <!-- end .button-->
                  </div>
                </div>
              </div>
              <div class="service-calculator__verification-group">
                <div class="service-calculator__line">
                  <!-- begin .form-control-->
                  <!-- Modifiers-->
                  <!-- form-control_state_invalid - red border, one of the two options to show invalid field-->
                  <div class="form-control form-control_size_l">
                    <label class="form-control__holder">
                      <span class="form-control__label">
                        {{ labels.verificationCode.text }}
                      </span>
                      <span class="form-control__field">
                        <!-- Modifiers-->
                        <!-- form-control__input_state_invalid - red border, one of the two options to show invalid field-->
                        <input
                          ref="verificationCode"
                          v-model="verificationCode"
                          type="text"
                          class="form-control__input"
                          :class="{
                            'form-control__input_state_invalid': verifyCodeChecked && !vefiryCodeValid
                          }"
                          :placeholder="labels.verificationCode.placeholder"
                          :disabled="!verifyCodeSent || !phoneNumber.completed || phoneNumberConfirmed"
                        />
                      </span>
                      <span v-if="verifyCodeChecked && !vefiryCodeValid"
                        class="form-control__messages">
                        <span class="form-control__message form-control__message_style_error">{{
                        labels.verificationConfirm.error
                        }}</span>
                      </span>
                    </label>
                  </div>
                  <!-- end .form-control-->
                </div>
                <div class="service-calculator__line-control service-calculator__line-control_width_l">
                  <div class="service-calculator__control">
                    <!-- begin .button-->
                    <button
                      class="button button_width_full button_size_m button_text-size_s button_weight_normal button_style_no-shadow"
                      type="button"
                      :disabled="!verificationCode || phoneNumberConfirmed"
                      @click="checkVerificationCode">
                        <span v-if="!isLoading.verificationCode" class="button__holder">
                          {{ labels.verificationConfirm.text }}
                        </span>
                        <span v-if="isLoading.verificationCode" class="button__holder">{{ labels.verificationConfirm.loading}}</span>
                    </button>
                    <!-- end .button-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="service-calculator__footer">
        <div class="service-calculator__final">
          <div class="service-calculator__summary">
            <!-- begin .summary-->
            <div class="summary">
              <div class="summary__main">
                <div class="summary__fields">
                  <div
                    v-if="discountValue"
                    class="summary__field"
                  >
                    <div class="summary__label">
                      {{ labels.price.text }}
                    </div>
                    <div class="summary__price">
                      <!-- begin .price-->
                      <div class="price price_type_old">
                        {{ formatOldPrice }}
                      </div>
                      <!-- end .price-->
                    </div>
                  </div>
                  <div
                    v-if="discountValue"
                    class="summary__field"
                  >
                    <div class="summary__label">
                      {{ labels.sale.text }}
                    </div>
                    <div class="summary__text">
                      {{ formatDicountValue }}
                    </div>
                  </div>
                  <div
                    v-if="totalPrice"
                    class="summary__field"
                  >
                    <div class="summary__label">
                      {{ labels.total.text }}
                    </div>
                    <div class="summary__price">
                      <!-- begin .price-->
                      <div class="price">
                        {{ formatTotalPrice }}
                      </div>
                      <!-- end .price-->
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="totalPrice"
                class="summary__note"
              >
                {{ labels.total.info }}
              </div>
              <div
                v-if="!from"
                class="summary__message"
              >
                {{ labels.from.empty }}
              </div>
              <div
                v-if="!to"
                class="summary__message"
              >
                {{ labels.to.empty }}
              </div>
              <div
                v-if="!path.time.value && to"
                class="summary__message"
              >
                {{ labels.path.notFound }}
              </div>
            </div>
            <!-- end .summary-->
          </div>
          <div class="service-calculator__submit">
            <div class="service-calculator__control">
              <!-- begin .button-->
              <button
                class="button button_width_full button_text-size_m-s button_text-size_l-default"
                type="button"
                :disabled="!submitIsActive"
                @click.prevent="createOrder"
              >
                <span
                  v-if="!isLoading.order"
                  class="button__holder"
                >{{ labels.submit.text }}</span>
                <span
                  v-if="isLoading.order"
                  class="button__holder"
                >{{ labels.order.sending }}</span>
              </button>
              <!-- end .button-->
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="order.id"
        class="service-calculator__message-wrapper"
      >
        <div class="service-calculator__title">
          {{ labels.submitMessage.successTitle.replace('[ORDER_ID]', order.id) }}
        </div>
        <div class="service-calculator__fields">
          <p>{{ labels.submitMessage.successMessage }}</p>
        </div>
        <div class="service-calculator__controls">
          <div class="service-calculator__control">
            <!-- begin .button-->
            <button
              type="button"
              class="button button_width_full"
              @click.prevent="order = {}"
            >
              <span class="button__holder">{{ labels.submitMessage.close }}</span>
            </button>
            <!-- end .button-->
          </div>
        </div>
      </div>
    </template>
    <Loader
      class="service-calculator__loader"
      :class="{
        'service-calculator__loader_state_active': isLoading.setup
      }"
    />

    <div
      v-if="errorList.length"
      class="service-calculator__errors"
    >
      <div
        v-for="error in errorList"
        :key="error.message"
        class="service-calculator__alert service-calculator__alert_style_danger"
        v-html="error.message"
      />
    </div>
  </form>
  <!-- end .service-calculator-->
</template>

<script>
import VehicleCarousel from './components/VehicleCarousel.vue'
import Loader from './components/Loader.vue'
import { loadYmap } from 'vue-yandex-maps'
import { getInitData, getDiscount, getPhoneNumberVerificationSms, phoneNumberVerify, sendOrderRequest } from './api';
import { vMaska } from "maska"

export default {
  name: 'App',
  directives: { maska: vMaska },
  components: { VehicleCarousel, Loader },
  data() {
    return {
      defaultData: {
        isAuth: false,
        currency: 'руб.',
        labels: {
          from: {
            placeholder: 'Откуда везем',
            empty: 'Укажите точку отправления'
          },
          to: {
            placeholder: 'Куда везем',
            empty: 'Укажите точку назначения'
          },
          loader: {
            text: 'Грузчики'
          },
          passengers: {
            text: 'Пассажиры'
          },
          moveToUpstairs: {
            text: 'Подъем на этаж',
            placeholder: 'Какой этаж?'
          },
          dateTime: {
            text: 'Дата и время приезда',
            placeholder: 'Ввести дату'
          },
          duration: {
            text: 'Продолжительность',
            placeholder: 'Продолжительность'
          },
          promoCode: {
            text: 'Промокод',
            placeholder: 'Введите промокод',
            checking: 'Проверяем промокод',
            notFound: 'Неверный код'
          },
          phoneNumber: {
            text: 'Номер телефона',
            send: 'Отправить',
            placeholder: '+7 (___) ___ - __ - __',
            error: 'Введите номер телефона',
            loading: 'Отправка'
          },
          verificationCode: {
            text: 'Проверочный код',
            placeholder: 'Введите код',
            loading: 'Загрузка'
          },
          verificationConfirm: {
            text: 'Подтвердить',
            error: 'Код введен неверно. Попробуйте еще раз',
            loading: 'Загрузка'
          },
          price: {
            text: 'Стоимость доставки'
          },
          sale: {
            text: 'Скидка'
          },
          total: {
            text: 'Итого*',
            info: '*Точная цена заказа оговаривается с менеджером'
          },
          submit: {
            text: 'Заказать доставку'
          },
          errorSystem: {
            message: 'Произошла ошибка, попробуйте позднее'
          },
          path: {
            notFound: 'Путь не найден'
          },
          order: {
            sending: 'Отправка',
          },
          submitMessage: {
            close: 'Закрыть',
            successTitle: 'Заказ № [ORDER_ID] успешно оформлен',
            successMessage: 'Наш менеджер свяжется с вами в ближайшее время'
          },
          ui: {
            increase: 'Прибавить',
            decrease: 'Убавить'
          }
        },
        from: null,
        to: null,
        services: {
          auto: [],
          floor_rate: {},
          loader_wage: {}
        },
      },
      initData: {},
      storage: {},
      isReady: false,
      fatalError: false,
      errorList: [],
      settings: {
        apiKey: null,
        lang: 'ru_RU',
        coordorder: 'latlong',
        enterprise: false,
        version: '2.1'
      },
      ymapsIsReady: false,
      routError: false,
      path: {
        length: {
          value: 0
        },
        time: {
          value: 0,
          jamsValue: 0
        },
      },

      isLoading: {
        setup: true,
        promoCode: false,
        verificationCode: false,
        verificationSms: false,
        order: false,
      },

      numberOfPassengers: 0,
      numberOfLoader: 0,

      moveToUpstairs: false,

      floor: null,

      phoneNumber: {
        masked: '',
        unmasked: '',
        completed: false
      },

      promoCode: null,

      verificationCode: null,

      from: null,
      to: null,

      inputDelay: 450,
      timeouts: {
        position: null
      },

      isAuth: false,

      promoDiscount: {},

      promoCodeChecked: false,
      phoneNumberConfirmed: false, // Телефонный номер проверен
      phoneNumberError: false,

      verifyCodeSent: false, // Смс для проверки оправлена
      verifyCodeChecked: false, // Код проверен
      vefiryCodeValid: false, // Код подверждения валидный
      timeoutBlocking: false, // Таймаут 30 секунд
      timeoutCounter: 30,

      order: {},
      dateTime: null,

      currentServiceID: null,
      currentAutoID: 0,
      suggestScope: 'Россия',
      authUserPhoneNumber: null,
    }
  },
  computed: {
    labels() {
      if (typeof this.storage.labels !== 'undefined') {
        return this.storage.labels;
      } else {
        return this.defaultData.labels;
      }
    },

    discount() {
      let result = {};

      if (typeof this.promoDiscount.value !== 'undefined' && this.promoDiscount.value !== 0) {
        result = this.promoDiscount;
      } else if (typeof this.storage.discount !== 'undefined') {
        result = this.storage.discount;
      }

      return result;
    },

    hourlyRate() {
      let result = 0;

      if (typeof this.currentAuto.price !== 'undefined') {
        result = this.currentAuto.price;
      }

      return result;
    },

    floorRate() {
      let result = 0;

      if (typeof this.services.floor_rate !== 'undefined' && typeof this.services.floor_rate.price !== 'undefined') {
        result = this.services.floor_rate.price;
      }

      return result;
    },

    loaderWage() {
      let result = 0;

      if (typeof this.services.loader_wage !== 'undefined' && typeof this.services.loader_wage.price !== 'undefined') {
        result = this.services.loader_wage.price;
      }

      return result;
    },

    services() {
      let result = {};

      if (typeof this.storage.services !== 'undefined') {
        result = this.storage.services;
      }

      return result;
    },

    auto() {
      let result = [];

      if (typeof this.services.auto !== 'undefined') {
        result = this.services.auto;
      }

      return result;
    },

    currentAutoIndex() {
      return this.getAutoIndexByID(this.currentAutoID);
    },

    currentAuto() {
      let result = {};

      if (typeof this.auto[this.currentAutoIndex] !== 'undefined') {
        result = this.auto[this.currentAutoIndex];
      }

      return result;
    },

    roundedNumberOfHours() {
      return Math.ceil(this.path.time.value / 3600);
    },

    // Важно! Расчет доставки происходит по времени, т.е. 1 час = 1500 рублей - например.
    price() {
      let result = 0;

      // Стоимость от времени
      if (this.path.time.value) {
        let hours = this.roundedNumberOfHours;

        result = this.hourlyRate * hours;
      }

      return result;
    },

    loaderPrice() {
      let result = 0;

      if (this.loaderWage) {
        result = this.numberOfLoader * this.loaderWage;
      }

      return result;
    },

    floorPrice() {
      let result = 0;

      if (this.floor && this.floorRate && this.moveToUpstairs) {
        let number = this.floor > 1 ? this.floor - 1 : this.floor;

        result = number * this.floorRate;
      }

      return result;
    },

    fullPrice() {
      let price = this.price;

      if (price > 0) {

        // Добавляем дополнительыне услуги
        if (this.extraPrice) {
          price += this.extraPrice;
        }

      }
      return price;
    },

    totalPrice() {
      return this.fullPrice - this.discountValue;
    },

    extraPrice() {
      let result = 0;

      // Добавляем стоимость грузчиков
      if (this.loaderPrice) {
        result += this.loaderPrice;
      }

      // Добавляем стоимость подъема на этаж
      if (this.floorPrice) {
        result += this.floorPrice;
      }

      return result;
    },

    discountValue() {
      let value = 0;

      if (this.discount.value) {
        if (this.fullPrice && this.discount) {
          if (this.discount.type === 'percent') {
            value = this.fullPrice * (this.discount.value / 100);

          } else {
            value = this.discount.value;
          }
        }
      }

      return value;
    },

    formatOldPrice() {
      return this.formatPrice(this.fullPrice);
    },

    formatTotalPrice() {
      return this.formatPrice(this.totalPrice);
    },

    formatDicountValue() {
      let result = '-';

      if (this.discount.type === 'percent') {
        result += this.discount.value + '%';
      } else {
        result += this.formatPrice(this.discountValue);
      }

      return result;
    },

    dataIsReady() {
      let result = false;

      if (this.auto.length) {
        result = true;
      }

      return result;
    },

    maximumNumberOfPeople() {
      let result = 0;

      if (typeof this.currentAuto.peopleCapacity !== 'undefined') {
        result = parseInt(this.currentAuto.peopleCapacity) - 1 || 0;
      }

      return result;
    },

    totalNumberOfPeople() {
      return parseInt(this.numberOfLoader + this.numberOfPassengers);
    },

    freePlaces() {
      return parseInt(this.maximumNumberOfPeople - this.totalNumberOfPeople);
    },

    submitIsActive() {
      let result = false;

      if ((this.isAuth && this.totalPrice) || (this.totalPrice && this.phoneNumberConfirmed)) {
        result = true;
      }

      if (this.isLoading.order || this.isLoading.promoCode) {
        result = false;
      }

      if (!this.dateTime) {
        result = false;
      }

      return result;
    },

    basket() {
      let floor = {};
      if (typeof this.initData.services !== 'undefined' && typeof this.initData.services.floor_rate !== 'undefined') {
        let number = this.floor > 1 ? this.floor - 1 : this.floor;
        floor = {
          id: parseInt(this.initData.services.floor_rate.id),
          qty: number,
          price: this.initData.services.floor_rate.price
        }
      }

      let loader = {};
      if (typeof this.initData.services !== 'undefined' && typeof this.initData.services.loader_wage !== 'undefined') {
        loader = {
          id: parseInt(this.initData.services.loader_wage.id),
          qty: this.numberOfLoader || 0,
          price: this.initData.services.loader_wage.price
        }
      }

      let auto = {};
      if (typeof typeof this.currentAuto.id !== 'undefined') {
        auto = {
          id: this.currentAutoID,
          qtyLength: Math.ceil(this.path.length.value),
          qty: this.roundedNumberOfHours,
          price: this.currentAuto.price
        }
      }

      let result = [].concat(floor, loader, auto)

      return result;
    },

    orderData() {
      let result = {
        serviceId: this.currentServiceID,
        userId: parseInt(this.initData.userId) || 0,
        from: this.from,
        to: this.to,
        date: this.dateTime,
        promoCode: this.promoCode,
        passengers: this.numberOfPassengers,
        price: this.price,
        totalPrice: this.totalPrice,
        extraPrice: this.extraPrice,
        basket: this.basket,
        phone: this.phoneNumber.masked || this.authUserPhoneNumber,
        recaptcha_response: this.getRecaptchaResponse()
      };

      return result;
    },
  },
  watch: {
    timeoutBlocking(status) {
      if (status) {
        setInterval(() => {
          this.timeoutCounter--;
          if (this.timeoutCounter == 0) {
            this.timeoutBlocking = false;
            this.timeoutCounter = 30
          }
        }, 1000);
      } else {
        this.timeoutCounter = 30;
      }

    },
    currentAutoID(newID) {
      let index = this.getAutoIndexByID(newID);
      this.currentAutoID = parseInt(this.auto[index].id) || 0;

      this.setNumberOfPassengers();
    },
    moveToUpstairs(newValue) {
      if (newValue) {
        setTimeout(() => this.$refs.moveToUpstairs.focus(), 300);
      }
    },
    storage: {
      immediate: true,
      deep: true,
      handler(data) {
        if (Object.keys(data).length) {
          if (typeof data.apiKey !== 'undefined') this.settings.apiKey = data.apiKey;

          if (typeof data.from !== 'undefined' && (data.isAuth || data.userId)) {
            this.from = data.from;
            this.$nextTick(() => {
              this.$refs.from.value = data.from
            });
          }

          if (typeof data.to !== 'undefined') {
            this.to = data.to;
            this.$nextTick(() => {
              this.$refs.to.value = data.to
            });
          }

          if (typeof data.phone !== 'undefined') {
            this.$nextTick(() => {
              if (data.phone) {
                this.authUserPhoneNumber = data.phone;
                this.phoneNumberConfirmed = true;
              }
            });
          }

          if (typeof data.isAuth !== 'undefined') this.isAuth = data.isAuth;
          //if (typeof data.userId !== 'undefined') this.isAuth = true;
          if (typeof data.numberOfLoader !== 'undefined') this.numberOfLoader = data.numberOfLoader;
          if (typeof data.numberOfPassengers !== 'undefined') this.numberOfPassengers = data.numberOfPassengers;

          if (typeof data.currentAutoID !== 'undefined') {
            this.currentAutoID = parseInt(data.currentAutoID);
          } else if (typeof window.currentAutoID !== 'undefined' && !this.currentAutoID) {
            this.currentAutoID = parseInt(window.currentAutoID);
          } else {
            if (typeof this.services !== 'undefined' && typeof this.services.auto !== 'undefined' && typeof this.services.auto[0] !== 'undefined') {
              this.currentAutoID = parseInt(data.services.auto[0].id) || 0;
            }
          }

          if (typeof window.currentServiceID !== 'undefined') this.currentServiceID = window.currentServiceID;

          // Проверка целостности данных
          this.dataCheck();
        }
      }
    },
    isReady(appIsReady) {
      if (appIsReady) {
        this.initYaMaps();
      }
    }
  },
  mounted() {
    this.loadInitData();
    this.initExternalLibraries();

    this.dateTime = (new Date()).getTime(); // Если дату не задать будет текущая
  },
  methods: {
    async initYaMaps() {
      await loadYmap({
        ...this.settings, debug: false
      }).then(() => {
        if (typeof window.ymaps !== 'undefined') {
          this.ymapsIsReady = true;

          this.initYaMapsSuggest();
        }
      });
    },

    initYaMapsSuggest() {
      const suggestFrom = new window.ymaps.SuggestView(this.$refs.from, {
        provider: {
          suggest: ((request) => {
            return window.ymaps.suggest(this.suggestScope + ', ' + request);
          })
        }
      }),
        suggestTo = new window.ymaps.SuggestView(this.$refs.to, {
          provider: {
            suggest: ((request) => {
              return window.ymaps.suggest(this.suggestScope + ', ' + request);
            })
          }
        });

      suggestFrom.events.add('select', e => {
        this.from = e.get('item').displayName;

        setTimeout(() => {
          if (this.to) {
            this.$refs.from.blur();
          } else {
            this.$refs.to.focus();
          }

          suggestFrom.state.set({ open: false });
        }, 100);

      });

      suggestTo.events.add('select', e => {
        this.to = e.get('item').displayName;

        setTimeout(() => {
          if (this.from) {
            this.$refs.to.blur();
          } else {
            this.$refs.from.focus();
          }

          suggestTo.state.set({ open: false });
        }, 100);
      });
    },

    // Создает ошибку
    createError(error = {}, isFatal = false) {
      if (typeof error.message === 'undefined') error.message = this.labels.errorSystem.message;

      // Некритичные ошибки собираем
      this.errorList.push(error);

      // Если создали критичную то останавливаем все
      if (isFatal) {
        this.fatalError = true;
        throw new Error(error.message, { cause: error });
      }
    },

    // Включает или выключает определенный лоадер
    setLoader(type, value) {
      if (typeof type !== 'undefined' && typeof this.isLoading[type] !== 'undefined') {
        this.isLoading[type] = !!value;
      }
    },

    // Инициализиурет внешние библиотеки
    initExternalLibraries() {
      document.addEventListener('readystatechange', () => {
        setTimeout(() => {
          if (typeof window.RollPicker !== 'undefined') {
            window.RollPicker.init(this.$refs.dateTime, {
              timestamp: (new Date()).getTime(),
              minDate: (new Date()).getTime(),
              hiddenInputs: {
                timestamp: this.$refs.timestamp,
              }
            });
          }
        }, 500);
      }, false);
    },

    // Загружает начальные данные
    loadInitData() {
      this.setLoader('setup', true);

      this.getInitData().then((result) => {
        this.parseSetups(result);
        // Ошибки
        if (result.errors.length) {
          this.errorList = result.errors;
        }
      }).catch(() => {
        this.createError({}, true);
        // Ошибки
      }).finally(() => {
        this.setLoader('setup', false);

        // Инициализируем калькулятор
        this.setStorage();
      });
    },

    // Получает начальные даныне
    getInitData() {
      return this.dataRequest();
    },

    // Получает скидку по промокоду
    getDiscountByPromoCode() {
      return this.dataRequest('getDiscount', {
        recaptcha_response: this.getRecaptchaResponse(),
        promoCode: this.promoCode
      });
    },

    // Запрашивает смс на проверку номера
    getPhoneNumberVerificationSms() {
      return this.dataRequest('getPhoneNumberVerificationSms', {
        recaptcha_response: this.getRecaptchaResponse(),
        phoneNumber: this.phoneNumber.masked
      });
    },

    // Запрашивает проверку код присланый в смс
    phoneNumberVerify() {
      return this.dataRequest('phoneNumberVerify', {
        recaptcha_response: this.getRecaptchaResponse(),
        phoneNumber: this.phoneNumber.masked,
        verificationCode: this.verificationCode
      });
    },

    // Отправляет заказ
    sendOrder() {
      return this.dataRequest('order', this.orderData);
    },

    // Отправляет запрос API
    dataRequest(type = 'init', params = {}) {
      this.errorList = [];

      if (type === 'init') {
        return getInitData(params, 'success');
      }

      if (type === 'getDiscount') {
        return getDiscount(params);
      }

      if (type === 'getPhoneNumberVerificationSms') {
        return getPhoneNumberVerificationSms(params);
      }

      if (type === 'phoneNumberVerify') {
        return phoneNumberVerify(params);
      }

      if (type === 'order') {
        return sendOrderRequest(params);
      }
    },

    // Проверяет промокод
    checkPromoCode() {
      if (this.promoCode) {
        this.setLoader('promoCode', true);

        this.getDiscountByPromoCode().then((result) => {
          if (typeof result.data.discount !== 'undefined') {
            this.promoDiscount = Object.assign(this.promoDiscount, result.data.discount);

            if (typeof result.data.discount.value === 'undefined' || result.data.discount.value === 0) {
              this.resetPromoDiscount();
            }
          }
          // Ошибки
          if (result.errors.length) {
            this.errorList = result.errors;
          }
        }).catch(() => {
          this.createError({}, true);
        }).finally(() => {
          this.setLoader('promoCode', false);
          this.promoCodeChecked = true;
          setTimeout(() => {
            this.$refs.promoCode.focus();
          }, 300);
        });
      }
    },

    preventForm() {
      // Do Nothing
    },

    // Отправляет заказ
    createOrder() {
      this.setLoader('order', true);

      this.sendOrder().then((result) => {
        if (typeof result.data.order !== 'undefined' && 'undefined') {
          this.order = result.data.order;
        } else {
          this.createError();
        }
        // Ошибки
        if (result.errors.length) {
          this.errorList = result.errors;
        }
      }).catch(() => {
        this.createError({}, true);
        // Ошибки
      }).finally(() => {
        this.setLoader('order', false);
      });
    },

    // Разбирает полученные настройки
    parseSetups(setups) {
      // Собираем ошибки
      if (setups.errors.length) {
        this.errorList = setups.errors;
      }

      Object.assign(this.initData, setups.data);
    },

    setStorage() {
      this.storage = this.initData;

      for (let key in this.defaultData) {
        if (typeof this.storage[key] !== 'undefined') {
          if (key === 'labels') {
            for (let labelKey in this.defaultData[key]) {
              this.storage[key][labelKey] = Object.assign(this.defaultData[key][labelKey], this.storage[key][labelKey]);
            }
          }
        } else {
          this.storage[key] = this.defaultData[key];
        }
      }
    },

    dataCheck() {
      // Если достаточно данных для старта то инициализируем Яндекс карты
      if (this.dataIsReady) {
        this.isReady = true;
      }
    },

    async setRoutParams() {
      if (this.from && this.to) {
        await window.ymaps.route([this.from, this.to], {
          multiRoute: false
        }).done(function (route) {
          this.routError = false;

          this.path = {
            length: {
              humanValue: route.getHumanLength(),
              value: route.getLength(),
            },
            time: {
              humanValue: route.getHumanTime(),
              humanJamsValue: route.getHumanJamsTime(),
              jamsValue: route.getJamsTime(),
              value: route.getTime()
            }
          }
        }, (err) => {
          this.routError = true;
          this.resetPath();
          throw err;
        }, this);
      } else {
        this.resetPath();
      }
    },

    dateTimeChange() {
      this.dateTime = this.$refs.timestamp.value;
    },

    resetPath() {
      return this.path = {
        length: {
          value: 0
        },
        time: {
          value: 0,
          jamsValue: 0
        },
      };
    },

    enterRoutePoint() {
      // if (this.timeouts.position) clearTimeout(this.timeouts.position);

      // this.timeouts.position = setTimeout(() => {
      //   this.setRoutParams();
      // }, this.inputDelay);

      this.from = this.$refs.from.value;
      this.to = this.$refs.to.value;

      this.setRoutParams();
    },

    resetPromoDiscount() {
      this.prodmoDiscount = {};
    },

    formatPrice(number, currency) {
      let i, j, kw, kd, km, decimals = 0, dec_point = '.', thousands_sep = ' ', result;

      if (!currency) {
        currency = this.defaultData.currency;
      }

      i = parseInt(number = (+number || 0).toFixed(decimals)) + '';

      if ((j = i.length) > 3) {
        j = j % 3;
      } else {
        j = 0;
      }

      km = (j ? i.substr(0, j) + thousands_sep : '');
      kw = i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands_sep);
      kd = (decimals ? dec_point + Math.abs(number - i).toFixed(decimals).replace(/-/, 0).slice(2) : '');

      result = km + kw + kd + ' ' + currency

      return result;
    },

    changeAuto(autoID) {
      this.currentAutoID = autoID;
    },

    getAutoIndexByID(autoID) {
      let result = 0;

      if (typeof autoID !== 'undefined') {
        let index = this.auto.findIndex(elm => {
          return parseInt(elm.id) === parseInt(autoID);
        });
        if (index > -1) result = index;
      }

      return result;
    },

    // Выставляет корректное количетсов пассажиров
    setNumberOfPassengers() {
      let extra = this.maximumNumberOfPeople - this.totalNumberOfPeople;

      if (extra < 0) {
        let overLimit = Math.abs(extra),
        diff = this.numberOfPassengers - overLimit;

        if (diff < 0) {
          this.numberOfPassengers = 0;
          overLimit = Math.abs(diff);

          if (overLimit > this.numberOfLoader) {
            this.numberOfLoader = 0;
          } else {
            this.numberOfLoader -= overLimit;
          }
        } else {
          this.numberOfPassengers = diff;
        }
      }

    },

    // Запрашивает код подтверждения
    sendVerifySms() {
      this.setLoader('verificationSms', true);
      this.getPhoneNumberVerificationSms().then((response) => {
        if (response.status !== 'error' && typeof response.data.smsId !== 'undefined') {
          this.verifyCodeSent = true;
          this.phoneNumberError = false;
        } else {
          this.verifyCodeSent = false;
          this.phoneNumberError = true;
        }

        if (this.verifyCodeSent) this.timeoutBlocking = true;
      }).catch(() => {
        console.log('Ошибка подтверждения номера телефона');
        this.verifyCodeSent = false;
        this.phoneNumberError = true;
      }).finally(() => {
        this.setLoader('verificationSms', false);
        if (this.verifyCodeSent) this.$refs.verificationCode.focus();
      });
    },

    // Проверяет код подтверждения
    checkVerificationCode() {
      if (this.verificationCode) {
        this.setLoader('verificationCode', true);

        this.phoneNumberVerify().then((response) => {
          if (response.status !== 'error' && typeof response.data.isValid !== 'undefined') {
            this.phoneNumberConfirmed = response.data.isValid;
            this.vefiryCodeValid = response.data.isValid;
          } else {
            this.vefiryCodeValid = false;
            this.phoneNumberConfirmed = false;
            this.createError({}, true);
          }
        }).catch(() => {
          this.createError({}, true);
          this.phoneNumberConfirmed = false;
        }).finally(() => {
          this.setLoader('verificationCode', false);
          this.verifyCodeChecked = true;
        });
      }
    },

    // Получает каптчю
    getRecaptchaResponse() {
      let result = null,
      rInput = document.querySelector('.recaptchaResponse');

      if (rInput) {
        result = rInput.value;
      }

      return result;
    }
  }
}
</script>

<style src="./assets/styles.css">

</style>
