export const getInitData = async (params = {}) => {
	return window.BX.ajax.runComponentAction("waim:calculator", "init", {
		mode: 'class',
		data: params
	});
}

export const getDiscount = async (params = {}) => {
	return window.BX.ajax.runComponentAction("waim:calculator", "getDiscount", {
		mode: 'class',
		data: params
	});
}

export const getPhoneNumberVerification = async (params = {}) => {
	return window.BX.ajax.runComponentAction("waim:calculator", "getPhoneNumberVerification", {
		mode: 'class',
		data: params
	});
}

export const sendOrderRequest = async (params = {}) => {
	return window.BX.ajax.runComponentAction("waim:calculator", "sendOrderRequest", {
		mode: 'class',
		data: params
	});
}

export const getPhoneNumberVerificationSms = async (params = {}) => {
	return window.BX.ajax.runComponentAction("waim:calculator", "getPhoneNumberVerificationSms", {
		mode: 'class',
		data: params
	});
}

export const phoneNumberVerify = async (params = {}) => {
	return window.BX.ajax.runComponentAction("waim:calculator", "phoneNumberVerify", {
		mode: 'class',
		data: params
	});
}
