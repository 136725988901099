<template>
    <div
        class="loader"
    >
        <div class="loader__wrapper">
            <svg
                id="L9"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 100 100"
                enable-background="new 0 0 0 0"
                xml:space="preserve"
                class="loader__image"
            >
            <path
                d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
            >
                <animateTransform
                    attributeName="transform"
                    attributeType="XML"
                    type="rotate"
                    dur=".7s"
                    from="0 50 50"
                    to="360 50 50"
                    repeatCount="indefinite"
                />
            </path>
            </svg>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Loader',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .loader__wrapper {
    position: relative;
    height: 100%;
    width: 100%;
    min-height: 100px;
    background: rgba(255, 255, 255, .7)
  }

  .loader__image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 80px;
    fill: #343434;
  }
</style>
