<template>
    <!-- begin .vehicle-carousel-->
    <div class="vehicle-carousel">
        <swiper ref="autoCarousel" :observer=true slides-per-view="1" @slideChange="onSlideChange"
            @slidesLengthChange="onSlidesLengthChanger" :initialSlide="currentIndex"
            class="vehicle-carousel__container">
            <swiper-slide class="vehicle-carousel__slide swiper-slide" v-for="slide in slides" :key="slide.id">
                <!-- begin .vehicle-panel-->
                <div class="vehicle-panel vehicle-carousel__slide-wrapper">
                    <div class="vehicle-panel__wrapper">
                        <div class="vehicle-panel__main">
                            <div class="vehicle-panel__illustration">
                                <picture class="vehicle-panel__picture" v-if="slide.image">
                                    <img width="90" height="70" class="vehicle-panel__image lazyload" :alt="slide.title"
                                        :title="slide.title" :src="slide.image" />
                                </picture>
                            </div>
                            <div class="vehicle-panel__info">
                                <div class="vehicle-panel__label" v-if="slide.title">{{ slide.title }}</div>
                                <div class="vehicle-panel__props" v-if="slide.props.dimensions">
                                    <div class="vehicle-panel__prop">{{ slide.props.dimensions }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="vehicle-panel__description" v-if="slide.description">{{ slide.description }}</div>
                    </div>
                </div>
                <!-- end .vehicle-panel-->
            </swiper-slide>
        </swiper>
        <div class="vehicle-carousel__navigation" v-if="slides.length > 1">
            <!-- begin .carousel-nav-->
            <div class="carousel-nav carousel-nav_position_sides js-carousel-nav" data-nav-scope=".vehicle-carousel"
                data-nav-target=".swiper">
                <div class="carousel-nav__control">
                    <button type="button" class="carousel-nav__arrow carousel-nav__arrow_type_prev js-carousel-nav-prev"
                        @click="prevSlide()">
                        Предыдущий слайд
                    </button>
                </div>
                <div class="carousel-nav__control">
                    <button type="button" class="carousel-nav__arrow carousel-nav__arrow_type_next js-carousel-nav-next"
                        @click="nextSlide()">
                        Следующий слайд
                    </button>
                </div>
            </div>
            <!-- end .carousel-nav-->
        </div>
    </div>
    <!-- end .vehicle-carousel-->
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";

import 'swiper/css';

export default {
    name: 'VehicleCarousel',
    components: { Swiper, SwiperSlide },
    props: {
        slides: Array,
        currentIndex: Number
    },
    data() {
        return {
        }
    },
    watch: {
    },
    methods: {
        onSlidesLengthChanger() {
            this.goToCurrentIndex();
        },

        onSlideChange(swiper) {
            let index = swiper.realIndex;

            if (typeof this.slides[index] !== 'undefined') {
                let auto = this.slides[index];
                this.$emit('change', parseInt(auto.id));
            }
        },

        nextSlide() {
            if (typeof this.$refs.autoCarousel.$el.swiper.slideNext !== 'undefined') this.$refs.autoCarousel.$el.swiper.slideNext();
        },

        prevSlide() {
            if (typeof this.$refs.autoCarousel.$el.swiper.slidePrev !== 'undefined') this.$refs.autoCarousel.$el.swiper.slidePrev();
        },

        goToSlide(index) {
            if (typeof this.$refs.autoCarousel.$el.swiper !== 'undefined') this.$refs.autoCarousel.$el.swiper.slideTo(index);
        },

        goToCurrentIndex() {
            this.goToSlide(this.currentIndex);
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
